import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'CNfbWow6EY9mzTYLtIEzTP1Cus4YirjOAgKd',
  sdkSecret: 'gvbQsTXdHnvisoDteF9kzSfKd4CG9wtcJCFX',
  webEndpoint: 'zoom.us', // zfg use www.zoomgov.com
  // topic = sessionName
  topic: 'BuddyCompany-meeting',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};
// participant  {userId: 16879616, avatar: undefined, displayName: 'Chrome-941', isHost: false, audio: '', …}
// video-footer.tsx:503 participant  {userId: 16787456, avatar: undefined, displayName: 'Chrome-543', isHost: true, audio: '', …}
// video-footer.tsx:503 participant  {userId: 16876544, avatar: '', displayName: 'Chrome-941', isHost: false, audio: '', …}
